@import '../../resources/mixins';
@import '../../resources/colors';

.popup-box.create-group-popup {
    min-width: 300px;
    min-height:450px;
    width: 90%;
    min-height: 557px;
    overflow:hidden;
    background-color:#fff;
    position: fixed;
    left:50%;
    top:50%;
    transform:translate(-50%,-50%);
    z-index:1002;
    margin:0 auto;
    box-shadow:rgba(20,20,20,0.2) 0 16px 32px,rgba(20,20,20,0.04) 0 0 0 1px;
    border-radius:12px;
    display: none;

    .popup-close {
        position:absolute;
        width:32px;
        height:32px;
        border-radius:50%;
        top:12px;
        right:16px;
        background:url(./resources/clear.svg) center center no-repeat;
        cursor: pointer;
    }

    .popup-body {
        height: 100%;
        padding: 16px 20px;
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: space-between;

        .create-group-button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 44px;
            cursor: pointer;
            color: $white;
            padding: 0.625em 2.2em;
            border-radius: 5px;
        }

        .container {
            border-collapse: collapse;
            margin: 0;
            padding: 0;
            width: 100%;
            table-layout: fixed;

            .item-container {
                font-size: 1em;
                font-weight: 500;
                text-align: left;
            }

            .item-container.search {
                font-weight: normal;

                input.member-search {
                    box-sizing: border-box;
                    width: 100%;
                    border-radius: 8px;
                    padding: 5px 20px;
                    font-size: 1rem;
                    color: rgba(20, 20, 20, 0.6);
                    outline: none;
                }

                input.member-search-btn{
                    width: 14px;
                    height: 14px;
                    position: absolute;
                    left: 15px;
                    padding: 0.8em;
                    background: url(./resources/search-grey-icon.svg) center center no-repeat;
                }
            }

            .user-list {
                height: 320px;
                overflow-y: auto;
                display: block;
            }
            
                img {
                    width: 32px;
                    height: 32px;
                    margin-right: 5%;
                    float:left;
                }

                span.name {
                    margin-left: 10px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                span.avatar {
                    .presence {
                        left: -8px;
                    }
                }

                .checkbox {
                    width: 25px;
                    height: 25px;
                }
        }
    }
}

.popup-box.create-group-popup.show {
    display: block;
}

@media screen and (max-width: 900px) {

    .popup-box.create-group-popup {

        .popup-body {

            table {
                border: 0;
                
                thead {
                    border: none;
                    clip: rect(0 0 0 0);
                    height: 1px;
                    margin: -1px;
                    overflow: hidden;
                    padding: 0;
                    position: absolute;
                    width: 1px;
                }
            }
        }
    }
}

.dark {
    .popup-box.create-group-popup {
        .popup-close {
            background:url(./resources/cross-white-icon.svg) center center no-repeat rgba(255,255,255,0.1);
        }
    
        .popup-box {
            background-color:#333;
        }
    }
}

@media (min-width : 540px) {
    .popup-box.create-group-popup {
        width: 448px;
    }
}